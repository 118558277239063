<template>
  <div class="offset-md-2 col-md-8 card card-custom">
    <div class="card-body p-0">
      <div class="text-right">
        <button
          class="btn btn-secondary"
          @click="$router.push({ name: 'adminAccountUsers' })"
        >
          {{ $t("admin.cancelCreate") }}
        </button>
      </div>
      <!--begin: Wizard-->
      <div
        id="kt_wizard_v1"
        class="wizard wizard-1"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <!--begin: Wizard Nav-->
        <div class="wizard-nav border-bottom">
          <div class="wizard-steps p-8 p-lg-10">
            <div
              class="wizard-step"
              data-wizard-type="step"
              data-wizard-state="current"
            >
              <div class="wizard-label">
                <i class="wizard-icon fal fa-list"></i>
                <h3 class="wizard-title">1. {{ $t("admin.baseData") }}</h3>
              </div>
              <i class="wizard-arrow fal fa-chevron-right"></i>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <i class="wizard-icon fal fa-file-image"></i>
                <h3 class="wizard-title">
                  2. {{ $t("admin.profilePicture") }}
                </h3>
              </div>
              <i class="wizard-arrow fal fa-chevron-right"></i>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <i class="wizard-icon fal fa-user-gear"></i>
                <h3 class="wizard-title">3. {{ $t("admin.profile") }}</h3>
              </div>
              <i class="wizard-arrow fal fa-chevron-right"></i>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <i class="wizard-icon fal fa-circle-check"></i>
                <h3 class="wizard-title">
                  4. {{ $t("admin.reviewAndSubmit") }}
                </h3>
              </div>
              <i class="wizard-arrow last fal fa-chevron-right"></i>
            </div>
          </div>
        </div>
        <!--end: Wizard Nav-->
        <v-progress-linear v-if="isBusy" indeterminate color="primary" />
        <!--begin: Wizard Body-->
        <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
          <div class="col-xl-12 col-xxl-12">
            <!--begin: Wizard Form-->
            <form id="kt_form" class="form">
              <!--begin: Wizard Step 1-->
              <div
                class="pb-5"
                data-wizard-type="step-content"
                data-wizard-state="current"
              >
                <h3 class="mb-10 font-weight-bold text-dark">
                  {{ $t("admin.baseData") }}:
                </h3>
                <div class="form-group row align-items-center">
                  <label class="col-xl-3 col-lg-3 col-form-label">
                    {{ $t("admin.salutation") }}
                  </label>
                  <div class="col-md-9 col-lg-6 col-xl-4">
                    <b-form-input
                      v-model="admin.core_data.salutation"
                      class="form-control"
                      :placeholder="$t('admin.enterSalutation')"
                    ></b-form-input>
                  </div>
                </div>
                <div class="form-group row align-items-center">
                  <label class="col-xl-3 col-lg-3 col-form-label">
                    {{ $t("admin.title") }}
                  </label>
                  <div class="col-md-9 col-lg-6 col-xl-4">
                    <b-form-input
                      v-model="admin.core_data.title"
                      class="form-control"
                      :placeholder="$t('admin.enterTitle')"
                    ></b-form-input>
                  </div>
                </div>
                <div class="form-group row align-items-center">
                  <label class="col-xl-3 col-lg-3 col-form-label">
                    {{ $t("admin.firstName") }}
                    <span class="text-danger">*</span>
                  </label>
                  <div class="col-md-9 col-lg-6 col-xl-4">
                    <b-form-input
                      id="adminFirstName"
                      v-model="admin.core_data.firstname"
                      :placeholder="$t('admin.enterFirstName')"
                      aria-describedby="adminFirstName-help adminFirstName-feedback"
                      trim
                      class="form-control"
                      :class="
                        !$v.admin.core_data.firstname.required
                          ? 'is-invalid'
                          : 'is-valid' &&
                            !$v.admin.core_data.firstname.minLength
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      @input="validateFields(1)"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      v-if="!$v.admin.core_data.firstname.required"
                      id="adminLastName-feedback"
                    >
                      {{ $t("validation.required.firstname") }}
                    </b-form-invalid-feedback>
                    <b-form-invalid-feedback
                      v-if="!$v.admin.core_data.firstname.minLength"
                      id="adminLastName-feedback"
                    >
                      {{ $t("validation.minChars", { chars: 3 }) }}
                    </b-form-invalid-feedback>
                  </div>
                </div>
                <div class="form-group row align-items-center">
                  <label class="col-xl-3 col-lg-3 col-form-label">
                    {{ $t("admin.lastName") }}
                    <span class="text-danger">*</span>
                  </label>
                  <div class="col-md-9 col-lg-6 col-xl-4">
                    <b-form-input
                      id="adminLastName"
                      v-model="admin.core_data.lastname"
                      :placeholder="$t('admin.enterLastName')"
                      aria-describedby="adminLastName-help adminLastName-feedback"
                      trim
                      class="form-control"
                      :class="
                        !$v.admin.core_data.lastname.required
                          ? 'is-invalid'
                          : 'is-valid' && !$v.admin.core_data.lastname.minLength
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      @input="validateFields(1)"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      v-if="!$v.admin.core_data.lastname.required"
                      id="adminLastName-feedback"
                    >
                      {{ $t("validation.required.lastname") }}
                    </b-form-invalid-feedback>
                    <b-form-invalid-feedback
                      v-if="!$v.admin.core_data.lastname.minLength"
                      id="adminLastName-feedback"
                    >
                      {{ $t("validation.minChars", { chars: 3 }) }}
                    </b-form-invalid-feedback>
                  </div>
                </div>
                <div class="form-group row align-items-center">
                  <label class="col-xl-3 col-lg-3 col-form-label">
                    {{ $t("admin.email") }} <span class="text-danger">*</span>
                  </label>
                  <div class="col-md-9 col-lg-6 col-xl-4">
                    <b-form-input
                      id="adminEmail"
                      v-model="admin.email"
                      :placeholder="$t('admin.enterEmail')"
                      aria-describedby="adminEmail-help adminEmail-feedback"
                      trim
                      class="form-control"
                      :class="
                        !$v.admin.email.required
                          ? 'is-invalid'
                          : 'is-valid' && !$v.admin.email.email
                          ? 'is-invalid'
                          : 'is-valid' && !$v.admin.email.isUnique
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      @input="validateFields(1)"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      v-if="!$v.admin.email.required"
                      id="adminEmail-feedback"
                    >
                      {{ $t("validation.required.email") }}
                    </b-form-invalid-feedback>
                    <b-form-invalid-feedback
                      v-if="!$v.admin.email.email"
                      id="adminEmail-feedback"
                    >
                      {{ $t("validation.email.email") }}
                    </b-form-invalid-feedback>
                    <b-form-invalid-feedback
                      v-if="!$v.admin.email.isUnique"
                      id="adminEmail-feedback"
                    >
                      {{ $t("validation.unique.email") }}
                    </b-form-invalid-feedback>
                  </div>
                </div>
                <div class="form-group row align-items-center">
                  <label class="col-xl-3 col-lg-3 col-form-label">
                    {{ $t("general.active") }}
                  </label>
                  <div class="col-md-9 col-lg-6 col-xl-4">
                    <span class="switch switch-sm">
                      <label>
                        <input v-model="admin.active" type="checkbox" />
                        <span></span>
                      </label>
                    </span>
                  </div>
                </div>
                <div class="form-group row align-items-center">
                  <label class="col-xl-3 col-lg-3 col-form-label">
                    {{ $t("admin.admin") }}
                  </label>
                  <div class="col-md-9 col-lg-6 col-xl-4">
                    <span class="switch switch-sm">
                      <label>
                        <input v-model="admin.is_admin" type="checkbox" />
                        <span></span>
                      </label>
                    </span>
                  </div>
                </div>
              </div>
              <!--end: Wizard Step 1-->

              <!--begin: Wizard Step 2-->
              <div class="pb-5" data-wizard-type="step-content">
                <h4 class="mb-10 font-weight-bold text-dark">
                  {{ $t("admin.profilePicture") }}:
                </h4>
                <div class="admin-avatar-upload">
                  <ImageUploadCropper
                    aspect-ratio="1"
                    :default-image="adminProfilePicture"
                    :upload-button-text="$t('admin.uploadProfilePicture')"
                    :crop-button-text="$t('admin.cropProfilePicture')"
                    :remove-button-text="$t('admin.removeProfilePicture')"
                    @cropMode="edit = $event"
                    @fileUpdated="files = $event"
                    @fileRemoved="fileRemoved()"
                  >
                  </ImageUploadCropper>
                </div>
              </div>
              <!--end: Wizard Step 2-->

              <!--begin: Wizard Step 3-->
              <div class="pb-5" data-wizard-type="step-content">
                <h4 class="mb-10 font-weight-bold text-dark">
                  {{ $t("admin.profile") }}:
                </h4>
                <div class="form-group row align-items-center">
                  <label class="col-xl-3 col-lg-3 col-form-label">
                    {{ $t("admin.password") }}
                    <span class="text-danger">*</span>
                  </label>
                  <div class="col-md-9 col-lg-6 col-xl-6">
                    <div class="input-group">
                      <div
                        class="input-group-prepend"
                        @click="copyText(password)"
                      >
                        <span class="input-group-text">
                          <i class="fal fa-copy cursor-pointer" />
                        </span>
                      </div>
                      <input
                        v-model="password"
                        :type="[
                          showPasswords['password'] ? 'text' : 'password'
                        ]"
                        class="form-control pr-0"
                        :class="passwordValidationClass"
                        @input="validateFields(3)"
                      />
                      <div
                        class="input-group-append"
                        @click="togglePassword('password')"
                      >
                        <span class="input-group-text">
                          <i
                            class="fal icon-lg cursor-pointer"
                            :class="[
                              showPasswords['password']
                                ? 'fa-eye-slash'
                                : 'fa-eye'
                            ]"
                          ></i>
                        </span>
                      </div>
                      <b-form-invalid-feedback v-if="!$v.password.required">
                        {{ $t("validation.required.password") }}
                      </b-form-invalid-feedback>
                      <b-form-invalid-feedback v-if="!$v.password.minLength">
                        {{ $t("validation.minChars", { chars: 8 }) }}
                      </b-form-invalid-feedback>
                      <b-form-invalid-feedback v-if="!$v.password.alphaNum">
                        {{ $t("validation.alphaNum") }}
                      </b-form-invalid-feedback>
                      <b-form-invalid-feedback v-if="!$v.password.upLowCase">
                        {{ $t("validation.upLowCase") }}
                      </b-form-invalid-feedback>
                    </div>
                  </div>
                </div>
                <div class="form-group row align-items-center">
                  <label class="col-xl-3 col-lg-3 col-form-label">
                    {{ $t("admin.passwordConfirmation") }}
                    <span class="text-danger">*</span>
                  </label>
                  <div class="col-md-9 col-lg-6 col-xl-6">
                    <div class="input-group">
                      <div
                        class="input-group-prepend"
                        @click="copyText(passwordConfirmation)"
                      >
                        <span class="input-group-text">
                          <i class="fal fa-copy cursor-pointer" />
                        </span>
                      </div>
                      <input
                        v-model="passwordConfirmation"
                        :type="[showPasswords['confirm'] ? 'text' : 'password']"
                        class="form-control pr-0"
                        :class="
                          !$v.passwordConfirmation.sameAs
                            ? 'is-invalid'
                            : 'is-valid' && !$v.passwordConfirmation.required
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        @input="validateFields(3)"
                      />
                      <div
                        class="input-group-append"
                        @click="togglePassword('confirm')"
                      >
                        <span class="input-group-text">
                          <i
                            class="fal icon-lg cursor-pointer"
                            :class="[
                              showPasswords['confirm']
                                ? 'fa-eye-slash'
                                : 'fa-eye'
                            ]"
                          ></i>
                        </span>
                      </div>
                      <b-form-invalid-feedback
                        v-if="!$v.passwordConfirmation.sameAs"
                        id="adminPasswordConfirmation-feedback"
                      >
                        {{ $t("validation.samePassword") }}
                      </b-form-invalid-feedback>
                      <b-form-invalid-feedback
                        v-if="
                          !$v.passwordConfirmation.required &&
                          $v.passwordConfirmation.sameAs
                        "
                        id="adminPasswordConfirmation-feedback"
                      >
                        {{ $t("validation.samePassword") }}
                      </b-form-invalid-feedback>
                    </div>
                  </div>
                </div>

                <div class="form-group row align-items-center">
                  <label class="col-xl-3 col-lg-3 col-form-label">
                    {{ $t("admin.language") }}
                  </label>
                  <div class="col-md-9 col-lg-6 col-xl-6">
                    <v-select
                      v-model="admin.language_id"
                      class="form-control"
                      :items="languageOptions"
                      item-text="label"
                      item-value="id"
                      :menu-props="{ offsetY: true }"
                      @change="languageChanged"
                    />
                    <b-form-text>{{ $t("admin.selectLanguage") }}</b-form-text>
                  </div>
                </div>
              </div>
              <!--end: Wizard Step 3-->

              <!--begin: Wizard Step 4-->
              <div class="pb-5" data-wizard-type="step-content">
                <h4 class="mb-10 font-weight-bold text-dark">
                  {{ $t("admin.reviewAndSubmit") }}:
                </h4>
                <div class="row">
                  <div class="col-md-3">
                    <div class="symbol symbol-150 mr-5 text-center">
                      <img
                        class="symbol-label"
                        :src="
                          files.length ? files[0].url : 'media/users/blank.png'
                        "
                      />
                      <br />
                      <b-badge
                        v-if="admin.active"
                        block
                        variant="success"
                        class="text-uppercase d-block"
                      >
                        {{ $t("general.active") }}
                      </b-badge>
                      <b-badge
                        v-else
                        block
                        variant="secondary"
                        class="text-uppercase d-block"
                      >
                        {{ $t("general.inactive") }}
                      </b-badge>

                      <b-badge
                        v-if="admin.is_admin"
                        variant="success"
                        class="text-uppercase mt-1 d-block"
                      >
                        {{ $t("admin.admin") }}
                      </b-badge>
                    </div>
                  </div>
                  <div
                    class="col-md-9 font-size-lg text-left"
                    style="line-height: 2"
                  >
                    <strong>{{ $t("admin.salutation") }}</strong
                    >: {{ admin.core_data.salutation }} <br />
                    <strong>{{ $t("admin.title") }}</strong
                    >: {{ admin.core_data.title }} <br />
                    <strong>{{ $t("admin.firstName") }}</strong
                    >: {{ admin.core_data.firstname }} <br />
                    <strong>{{ $t("admin.lastName") }}</strong
                    >: {{ admin.core_data.lastname }} <br />
                    <strong>{{ $t("admin.email") }}</strong
                    >: {{ admin.email }} <br />
                    <strong>{{ $t("admin.language") }}</strong
                    >: {{ selectedLanguageLabel }}<br />
                  </div>
                </div>
              </div>
              <!--end: Wizard Step 4-->

              <!--begin: Wizard Actions -->
              <div class="d-flex justify-content-between border-top pt-10">
                <div class="mr-2">
                  <button
                    :class="
                      wizardNavigationDisabled ? 'btn-secondary' : 'btn-primary'
                    "
                    class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                    data-wizard-type="action-prev"
                  >
                    {{ $t("general.previous") }}
                  </button>
                </div>
                <div>
                  <button
                    class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                    data-wizard-type="action-submit"
                    @click="saveAdmin"
                  >
                    {{ $t("admin.adminCreate") }}
                  </button>
                  <button
                    class="btn font-weight-bold text-uppercase px-9 py-4"
                    :class="
                      wizardNavigationDisabled ? 'btn-secondary' : 'btn-primary'
                    "
                    data-wizard-type="action-next"
                  >
                    {{ $t("general.next") }}
                  </button>
                </div>
              </div>
              <!--end: Wizard Actions -->
            </form>
            <!--end: Wizard Form-->
          </div>
        </div>
        <!--end: Wizard Body-->
      </div>
    </div>
    <!--end: Wizard-->
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-1.scss";
</style>

<script>
import KTWizard from "@/assets/js/components/wizard";
import Languages from "@/components/General/Languages/languages";
import Admins from "@/components/Admins/Account/Admin/admins";
import ImageUploadCropper from "@/components/Admins/Media/ImageUploadCropper";
import Media from "@/components/Admins/Media/media";

import {
  email,
  required,
  minLength,
  sameAs,
  helpers
} from "vuelidate/lib/validators";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Swal from "sweetalert2";
import { bus } from "@/main";

const alphaNum = helpers.regex(
  "alphaNum",
  /^(?=.*[a-zA-Z])(?=.*[0-9])[\w~@#$%^&*+=`|{}:;!.?"()[\]-]+$/
);
const upLowCase = helpers.regex(
  "upLowCase",
  /^(?=(?:.*[A-Z]){1})(?=(?:.*[a-z]){1}).*/
);

export default {
  validations: {
    admin: {
      core_data: {
        firstname: { required, minLength: minLength(3) },
        lastname: { required, minLength: minLength(3) }
      },
      email: {
        email,
        required,
        isUnique(value) {
          return !this.currentUsers.includes(value);
        }
      }
    },
    password: { required, minLength: minLength(8), alphaNum, upLowCase },
    passwordConfirmation: {
      required,
      sameAs: sameAs("password")
    }
  },
  name: "AdminsAccountAdminCreate",
  components: {
    ImageUploadCropper
  },
  data() {
    return {
      showPasswords: {
        password: false,
        confirm: false
      },
      adminProfilePicture: false,
      isBusy: false,
      files: [],
      edit: false,
      wizardNavigationDisabled: true,
      selectedLanguageLabel: "",
      languageOptions: [],
      sendWelcomeEmail: false,
      password: "",
      passwordConfirmation: "",
      admin: {
        email: "",
        active: false,
        is_admin: false,
        language_id: "",
        core_data: {
          salutation: "",
          title: "",
          firstname: "",
          lastname: ""
        }
      },
      currentUsers: []
    };
  },
  computed: {
    passwordValidationClass() {
      if (
        !this.$v.password.required ||
        !this.$v.password.minLength ||
        !this.$v.password.alphaNum ||
        !this.$v.password.upLowCase
      ) {
        return "is-invalid";
      }
      return "is-valid";
    }
  },
  mounted() {
    this.getLanguages();
    this.getAdmins();
    let wizard = new KTWizard("kt_wizard_v1", {
      startStep: 1,
      clickableSteps: false
    });
    wizard.on("change", wizardObj => {
      if (this.wizardNavigationDisabled) {
        wizardObj.stop();
      }
    });
    wizard.on("changed", wizardObj => {
      if (wizardObj.currentStep === 3) {
        this.validateFields(3);
      }
    });

    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("menu.tenantUserAdministration"),
        route: ""
      },
      {
        title: this.$t("general.overview"),
        route: "/admin/users/"
      },
      {
        title: this.$t("admin.adminCreate"),
        route: "/admin/users/add"
      }
    ]);
  },
  methods: {
    copyText(text) {
      this.$copyText(text);
      this.$toast.fire({
        icon: "info",
        title: this.$t("general.copied")
      });
    },
    togglePassword(passwordType) {
      this.showPasswords[passwordType] = !this.showPasswords[passwordType];
    },
    fileRemoved() {
      this.files = [];
      this.adminProfilePicture = false;
      this.admin.profile_picture_id = null;
    },
    getLanguages() {
      Languages.getAll()
        .then(response => {
          let self = this;
          response.data.data.forEach(async function (data) {
            self.admin.language_id = data.id;
            self.selectedLanguageLabel = data.label;
          });
          this.languageOptions = response.data.data;
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
        });
    },
    getAdmins() {
      let params = {
        page: 1,
        size: 100
      };
      Admins.getAll(params)
        .then(response => {
          let self = this;
          response.data.data.forEach(async function (data) {
            self.currentUsers.push(data.email);
          });
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
        });
    },
    languageChanged(e) {
      const language = this.languageOptions.find(l => l.id === e);
      if (language) {
        this.selectedLanguageLabel = language.label;
      }
    },

    validateFields(step) {
      this.wizardNavigationDisabled = true;
      this.$v.$touch();
      switch (step) {
        case 1:
          if (
            !this.$v.admin.email.$invalid &&
            !this.$v.admin.core_data.firstname.$invalid &&
            !this.$v.admin.core_data.lastname.$invalid
          ) {
            this.wizardNavigationDisabled = false;
          }
          break;
        case 3:
          if (
            !this.$v.password.$invalid &&
            !this.$v.passwordConfirmation.$invalid
          ) {
            this.wizardNavigationDisabled = false;
          }
          break;
      }
    },
    async saveAdmin(e) {
      e.preventDefault();
      this.isBusy = true;
      this.admin.name =
        this.admin.core_data.firstname + " " + this.admin.core_data.lastname;

      if (this.files.length) {
        let mediaData = new FormData();
        mediaData.append("files[]", this.files[0].file);
        mediaData.append(
          "description[]",
          "Admin Profile Picture - " + this.admin.name
        );
        await Media.store(mediaData)
          .then(response => {
            if (response.data.data.success) {
              this.admin.profile_picture_id = response.data.data.uploaded[0].id;
            }
          })
          .catch(error => {
            Swal.fire({
              title: this.$t("general.caution"),
              text: error.response.data.message,
              icon: "error"
            });
            this.isBusy = false;
          });
      }

      this.admin.password = this.password;

      Admins.store(this.admin)
        .then(() => {
          this.$toast.fire({
            icon: "success",
            title: this.$t("admin.adminCreated")
          });
          this.isBusy = false;
          bus.$emit("adminCreated");
          this.$router.push({ name: "adminAccountUsers" });
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    }
  }
};
</script>
